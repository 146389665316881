<template>
  <div v-if="!loadingInfo.isLoading && !loadingInfo.isError">
    <div id="mainGridContainer">
      <productDetailView
        v-if="openDetailPage"
        :openDetailPage="openDetailPage"
        @closeDialog="handleCloseDialog"
        :selectedRow="selectedRow"
        :height="detailHeight"
        :productDetail="selectedProduct"
      />
      <div v-show="!openDetailPage" id="adminPortalHomePage">
        <el-row class="myProductHeader">
          {{ $t('myProduct')
          }}<el-button class="downLoadBtn" @click="downloadCSVClick" type="primary" round>{{
            $t('downloadCSV')
          }}</el-button></el-row
        >
        <el-row class="headerRow" justify="space-between">
          <el-select
            @change="siteSelection"
            v-model="selectedSite"
            class="m-2 siteSelect"
            :placeholder="$t('siteSelectionPlaceholder')"
            size="small"
          >
            <el-option
              v-for="site in siteList"
              :key="site.LegalEntityID"
              :label="site.LegalEntityName"
              :value="site.LegalEntityID"
            />
          </el-select>
          <el-input
            :suffix-icon="Search"
            v-model="search"
            class="w-50 m-2"
            size="small"
            :placeholder="$t('searchPlaceholder')"
            clearable
            @change="searchTrigger"
          />
        </el-row>
        <el-table
          stripe
          class="productGrid"
          ref="productGrid"
          :data="productDataByPage"
          style="width: 100%"
          :height="tableBodyheight"
          @sort-change="handleSortChange"
          @filter-change="filterChange"
        >
          <el-table-column
            sortable
            column-key="productName"
            show-overflow-tooltip="true"
            prop="productName"
            :label="$t('productName')"
            width="310"
          >
            <template #default="scope">
              <span
                @click="handleCellClick(scope.row, scope.column)"
                :class="{ adminPortalClickableColumn: isClickable(scope.row, scope.column) }"
                >{{ scope.row.productName }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            sortable
            column-key="release"
            prop="release"
            :label="$t('release')"
            :filters="releaseFilter"
          />
          <el-table-column sortable prop="serialNumber" :label="$t('serialNumber')" width="250" />
          <el-table-column
            sortable
            column-key="supportEndDate"
            prop="supportEndDate"
            :label="$t('supportEndDate')"
            :filters="supportEndDateFilter"
          />
          <el-table-column
            sortable
            column-key="licenseType"
            prop="licenseType"
            :label="$t('licenseType')"
            width="180"
            :filters="licenseTypeFilter"
          />
          <el-table-column
            sortable
            width="180"
            column-key="expires"
            prop="expires"
            :formatter="formatter"
            :label="$t('expires')"
            :filters="expiresFilter"
          />
          <el-table-column
            width="180"
            sortable
            column-key="activated"
            prop="activated"
            :label="$t('activated')"
            :filters="activatedFilter"
          />
        </el-table>
        <el-pagination
          :current-page="currentPage"
          :page-size="15"
          :pager-count="15"
          layout="total,prev, pager, next"
          :total="totalProductCount"
          @update:current-page="handlePageNumberChange"
        />
      </div>
    </div>
  </div>
  <div v-else-if="loadingInfo.isError" class="userMessage">
    <div>{{ loadingInfo.message }}</div>
    <div>{{ loadingInfo.subMessage }}</div>
  </div>
</template>

<script>
import productDetailView from '../components/ProductDetailView.vue'
import API from '../API.js'
import { useI18n } from 'vue-i18n'

import { ElLoading, ElMessage } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import { ref, onMounted, computed } from 'vue'
export default {
  name: 'ProductionGridView',
  components: { productDetailView },
  setup() {
    const { t } = useI18n()
    let siteList = ref([]),
      tableData = ref([]),
      loadingInfo = ref({
        isLoading: true,
        isError: false,
        message: '',
        subMessage: ''
      }),
      productResponse = ref([]),
      selectedRow = ref([]),
      supportEndDateFilter = ref([]),
      licenseTypeFilter = ref([]),
      activatedFilter = ref([]),
      releaseFilter = ref([]),
      expiresFilter = ref([]),
      selectedProduct = ref([]),
      search = ref([]),
      currentPage = ref(1),
      tableBodyheight = ref([]),
      detailHeight = ref([]),
      getViewportHeight = ref([]),
      productDataByPage = ref([]),
      licenseTypeSelectedFilterValue = ref([]),
      expireFilterValue = ref([]),
      supportEndFilterValue = ref([]),
      activatedFilterValue = ref([]),
      releaseSelectedFilterValue = ref([]),
      selectedSite = ref([]),
      filteredTableData = ref([]),
      productGrid = ref(null),
      getCompleteTabelData = ref([]),
      totalProductCount = ref([]),
      openDetailPage = ref(false),
      tableRef = ref(null),
      currentPopover = ref('')
    //Call method to get product data
    getProductData()

    onMounted(() => {
      loadingInfo.value.isLoading = true
    })
    //As we have Solidworks header and footer in place hence set the height according to grid view
    tableBodyheight = computed(() => {
      return 690 + 'px'
    })
    //Get the total product count to paginate list locally
    totalProductCount = computed(() => {
      return tableData.value.length
    })
    //release filter data in desired format
    releaseFilter = computed(() => {
      return [...new Set(tableData.value.map((data) => data.release))].map((data) => {
        return {
          text: data,
          value: data
        }
      })
    })
    //support end date filter data in desired format
    supportEndDateFilter = computed(() => {
      return [...new Set(tableData.value.map((data) => data.supportEndDate))].map((data) => {
        return {
          text: data,
          value: data
        }
      })
    })
    //license type filter data in desired format
    licenseTypeFilter = computed(() => {
      return [...new Set(tableData.value.map((data) => data.licenseType))].map((data) => {
        return {
          text: data,
          value: data
        }
      })
    })
    //activated filter data in desired format
    activatedFilter = computed(() => {
      return [...new Set(tableData.value.map((data) => data.activated))].map((data) => {
        return {
          text: data,
          value: data
        }
      })
    })
    //expire filter data in desired format
    expiresFilter = computed(() => {
      return [...new Set(tableData.value.map((data) => data.expires))].map((data) => {
        return {
          text: data,
          value: data
        }
      })
    })

    //API call to get Product Data
    function getProductData() {
      let loadingInstance = ElLoading.service({
        target: window.body,
        lock: true,
        text: 'Fetching product details...'
      })
      loadingInfo.value.isLoading = true
      API.doGET({ path: 'OnlineInstance' })
        .then((response) => {
          if (response) {
            productResponse.value = response
            siteList.value = productResponse.value.profiles.map((profile) => {
              return {
                LegalEntityID: profile.LegalEntityID,
                LegalEntityName: profile.LegalEntityName
              }
            })
            selectedSite.value = siteList.value[0].LegalEntityID
            let profileBySite = productResponse.value.profiles.filter(
              (product) => product.LegalEntityID === selectedSite.value
            )
            getProductDataBySite(profileBySite)
          }
          loadingInstance.close()
          loadingInfo.value.isLoading = false
          loadingInfo.value.isError = false
        })
        .catch((error) => {
          if (error) {
            loadingInstance.close()
            ElMessage({
              type: 'error',
              message: error.message ? error.message : ''
            })
            console.log(error)
            loadingInfo.value.isLoading = false
            loadingInfo.value.isError = true
            loadingInfo.value.message = t('adminRightErrorMessageLine1')
            loadingInfo.value.subMessage = t('adminRightErrorMessageLine2')
          }
        })
        .finally(() => {
          loadingInstance.close()
        })
    }
    //SPLIT the product data by site selected (By default first site got selected from list)
    function getProductDataBySite(profileBySite) {
      let parent = [],
        child = []
      if (profileBySite.length > 0) {
        profileBySite[0].onlineInstances.map((onlineInstance) => {
          if (onlineInstance.OnlineInstanceType === 'SeatID Standalone') {
            onlineInstance.Entitlements.map((entitlement) => {
              let parentTrueObj = entitlement.productFeatures.filter(
                (product) => product.isParent === 'true'
              )
              //console.log(parentTrueObj[0])
              if (parentTrueObj.length > 0) {
                entitlement.productFeatures.map((product) => {
                  if (product.isParent === 'true') {
                    parent.push({
                      productName: entitlement.productName,
                      release: entitlement.maxRelease,
                      serialNumber: product.serialNumber,
                      isParent: product.isParent,
                      supportEndDate: entitlement.supportEndDate,
                      //LN8-263
                      licenseType: product.type,
                      expires: entitlement.usageEndDate,
                      activated: product.activated,
                      machines: product.machines,
                      OnlineInstanceId: onlineInstance.OnlineInstanceId
                    })
                  } else if (
                    parentTrueObj.length > 0 &&
                    parentTrueObj[0].serialNumber !== product.serialNumber &&
                    product.isParent !== 'true'
                  ) {
                    parent.push({
                      productName: product.name,
                      release: entitlement.maxRelease,
                      serialNumber: product.serialNumber,
                      isParent: product.isParent,
                      supportEndDate: entitlement.supportEndDate,
                      //LN8-263
                      licenseType: product.type,
                      //LN8-270
                      expires: product.expires,
                      activated: product.activated,
                      machines: product.machines,
                      OnlineInstanceId: onlineInstance.OnlineInstanceId
                    })
                  } else if (
                    parentTrueObj[0].serialNumber === product.serialNumber &&
                    product.isParent !== 'true'
                  ) {
                    child.push({
                      ...product,
                      // productName:product.name,
                      release: entitlement.maxRelease,
                      serialNumber: product.serialNumber,
                      supportEndDate: entitlement.supportEndDate,
                      licenseType: product.type,
                      expires: entitlement.usageEndDate
                    })
                  }
                })
              }
            })
          } else if (onlineInstance.OnlineInstanceType == 'SeatID Network') {
            if (
              onlineInstance.Entitlements.length > 0 &&
              onlineInstance.Entitlements[0].productFeatures.length > 0
            ) {
              parent.push({
                productName: 'SOLIDWORKS SolidNetWork Installation',
                release: onlineInstance.Entitlements[0].maxRelease,
                serialNumber: onlineInstance.Entitlements[0].productFeatures[0].serialNumber,
                isParent: onlineInstance.Entitlements[0].productFeatures.isParent,
                supportEndDate: onlineInstance.Entitlements[0].supportEndDate,
                licenseType: onlineInstance.Entitlements[0].productFeatures[0].type,
                expires: onlineInstance.Entitlements[0].usageEndDate,
                activated: onlineInstance.Entitlements[0].productFeatures[0].activated,
                machines: onlineInstance.Entitlements[0].productFeatures[0].machines,
                OnlineInstanceId: onlineInstance.OnlineInstanceId
              })
            }
            onlineInstance.Entitlements.map((entitlement) => {
              entitlement.productFeatures.map((product) => {
                child.push({
                  ...product,
                  // productName:product.name,
                  release: entitlement.maxRelease,
                  serialNumber: product.serialNumber,
                  supportEndDate: entitlement.supportEndDate,
                  licenseType: product.type,
                  expires: entitlement.usageEndDate
                })
              })
            })
          }
        })
      }
      tableData.value = parent
      if (Array.isArray(tableData.value) && tableData.value.length > 0) {
        tableData.value = tableData.value.map((tableRow) => {
          let childFound = child.filter((ch) => ch.serialNumber === tableRow.serialNumber)
          tableRow['children'] = childFound || []
          return tableRow
        })
      }
      getCompleteTabelData.value = tableData.value
      // productDataByPage.value = tableData.value;//
      productDataByPage.value = tableData.value.slice(
        (currentPage.value - 1) * 15,
        currentPage.value * 15
      )
    }

    function formatter(row, column) {
      let colName = column.property,
        colValue = row[colName]
      if (colName === 'expires') {
        return colValue === '2100-01-01' ? 'Never' : colValue
      }
    }
    //Handle click on product name and open detail page view
    function handleCellClick(row) {
      if (row.children.length > 0) {
        let filteredArrayByTrigramAndQuantity = []
        ;[...new Set(row.children.map((data) => data.trigram))].forEach((trigram) => {
          let filterByTrigram = row.children.filter((data) => data.trigram === trigram)
          //  console.log(filterByTrigram);
          let totalQantityByTrigram = filterByTrigram.reduce(function (accumulator, pilot) {
            return accumulator + parseInt(pilot.quantity)
          }, 0)
          // console.log(totalQantityByTrigram);
          filterByTrigram[0].quantity = totalQantityByTrigram
          filteredArrayByTrigramAndQuantity.push(filterByTrigram[0])
        })
        //console.log(filteredArrayByTrigramAndQuantity);
        row.children = filteredArrayByTrigramAndQuantity
      }
      selectedRow.value = row
      selectedProduct.value = row
      openDetailPage.value = true
    }
    function handlePageNumberChange(pageNumber) {
      currentPage.value = pageNumber
      productDataByPage.value = tableData.value.slice(
        (currentPage.value - 1) * 15,
        currentPage.value * 15
      )
    }
    //currently we do not have condition on isClickable hence returned true by default
    function isClickable() {
      return true
    }
    function handleCloseDialog() {
      openDetailPage.value = false
    }

    function siteSelection(id) {
      currentPage.value = 1
      let profileBySite = productResponse.value.profiles.filter(
        (product) => product.LegalEntityID === id
      )
      getProductDataBySite(profileBySite)
    }
    /**
     * Handle search locally (No backend call involved)
     * Handle Sort  locally (No backend call involved)
     * Handle Filter locally (No backend call involved)
     */

    function searchTrigger(searchValue) {
      productGrid.value.clearFilter()
      let searchByName = [],
        searchBySerialNumber = []
      console.log(releaseSelectedFilterValue.value)
      if (searchValue) {
        searchByName = getCompleteTabelData.value.filter(
          (data) =>
            !searchValue || data.productName.toLowerCase().includes(search.value.toLowerCase())
        )
        searchBySerialNumber = getCompleteTabelData.value.filter(
          (data) =>
            !searchValue || data.serialNumber.toLowerCase().includes(search.value.toLowerCase())
        )
        tableData.value = [...searchByName, ...searchBySerialNumber]
      } else {
        tableData.value = getCompleteTabelData.value
      }
      currentPage.value = 1
      // productDataByPage.value = tableData.value;
      productDataByPage.value = tableData.value.slice(
        (currentPage.value - 1) * 15,
        currentPage.value * 15
      )
    }
    function handleSortChange(sortProp) {
      if (sortProp.order === 'ascending')
        tableData.value = tableData.value.sort((a, b) =>
          a[sortProp.prop] > b[sortProp.prop] ? 1 : -1
        )
      else
        tableData.value = tableData.value.sort((a, b) =>
          b[sortProp.prop] > a[sortProp.prop] ? 1 : -1
        )
      currentPage.value = 1
      productDataByPage.value = tableData.value.slice(
        (currentPage.value - 1) * 15,
        currentPage.value * 15
      )
    }
    function filterChange(filters) {
      var filterKeys = Object.keys(filters)
      if (filterKeys.length > 0) {
        if (filterKeys[0] === 'expires') {
          expireFilterValue.value = filters[filterKeys[0]]
        } else if (filterKeys[0] === 'licenseType') {
          licenseTypeSelectedFilterValue.value = filters[filterKeys[0]]
        } else if (filterKeys[0] === 'release') {
          releaseSelectedFilterValue.value = filters[filterKeys[0]]
        } else if (filterKeys[0] === 'supportEndDate') {
          supportEndFilterValue.value = filters[filterKeys[0]]
        } else if (filterKeys[0] === 'activated') {
          activatedFilterValue.value = filters[filterKeys[0]]
        }
        let allFilters = {
          expires: expireFilterValue.value,
          licenseType: licenseTypeSelectedFilterValue.value,
          release: releaseSelectedFilterValue.value,
          supportEndDate: supportEndFilterValue.value,
          activated: activatedFilterValue.value
        }
        let allFiltersKeys = Object.keys(filters)
        if (allFiltersKeys.length > 0) {
          filteredTableData.value = getFilteredData(getCompleteTabelData.value, allFilters)
          console.log(filteredTableData.value)
          tableData.value = filteredTableData.value
          currentPage.value = 1
          productDataByPage.value = tableData.value.slice(
            (currentPage.value - 1) * 15,
            currentPage.value * 15
          )
        } else {
          tableData.value = getCompleteTabelData.value
        }
      }
    }
    function getFilteredData(targetArray, filters) {
      var filterKeys = Object.keys(filters)
      return targetArray.filter(function (product) {
        return filterKeys.every(function (key) {
          if (!filters[key].length) return true
          // Loops again if product[key] is an array (for other attribute).
          if (Array.isArray(product[key])) {
            return product[key].some((keyEle) => filters[key].includes(keyEle))
          }
          return filters[key].includes(product[key])
        })
      })
    }
    /**
     * Generate row for excel
     * Download excel
     */

    function getRowsForExcel() {
      let firstRow = [
          'Product Name',
          'Release',
          'Serial Number',
          'License Type',
          'Quantity',
          'Support End Date',
          'Expires',
          'Active machine list'
        ],
        gridRows = []
      getCompleteTabelData.value.forEach((product) => {
        gridRows.push([
          product.productName,
          product.release,
          product.serialNumber,
          product.licenseType,
          //the main page (the list) the quantity of every product shown is assumed to be 1 hence hardcoded quantity as 1
          1,
          product.supportEndDate,
          product.expires,
          product.machines.length > 0 ? product.machines.map((machine) => machine.name) : []
        ])

        if (product.children.length > 0) {
          product.children.forEach((child) => {
            gridRows.push([
              child.name,
              child.release,
              child.serialNumber,
              child.licenseType,
              child.quantity,
              child.supportEndDate,
              child.expires,
              child.machines.length > 0 ? child.machines.map((machine) => machine.name) : []
            ])
          })
        }
      })

      return [firstRow, ...gridRows]
    }
    function downloadCSVClick() {
      let filename = `MyProducts_${Date.now()}.csv`,
        rows = getRowsForExcel()

      var processRow = function (row) {
        var finalVal = ''
        for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? '' : row[j].toString()
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString()
          }
          var result = innerValue.replace(/"/g, '""')
          if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'
          if (j > 0) finalVal += ','
          finalVal += result
        }
        return finalVal + '\n'
      }

      var csvFile = ''
      for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i])
      }

      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement('a')
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', filename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }
    return {
      downloadCSVClick,
      formatter,
      productResponse,
      handleSortChange,
      productDataByPage,
      currentPage,
      handlePageNumberChange,
      totalProductCount,
      tableBodyheight,
      detailHeight,
      getViewportHeight,
      expireFilterValue,
      filteredTableData,
      search,
      Search,
      searchTrigger,
      tableData,
      tableRef,
      isClickable,
      handleCellClick,
      openDetailPage,
      handleCloseDialog,
      selectedProduct,
      selectedRow,
      currentPopover,
      siteList,
      selectedSite,
      siteSelection,
      supportEndDateFilter,
      expiresFilter,
      releaseFilter,
      licenseTypeFilter,
      activatedFilter,
      licenseTypeSelectedFilterValue,
      productGrid,
      filterChange,
      loadingInfo
    }
  }
}
</script>
>
