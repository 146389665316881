<template>
  <div id="parentView" :style="{ height: getHeight + 'px' }">
    <ProductGridView />
  </div>
</template>

<script>
import ProductGridView from './components/ProductGridView.vue'
export default {
  name: 'App',
  components: {
    ProductGridView
  },
  setup() {
    //set height to parent window
    function getHeight() {
      return window.innerHeight
    }

    return { getHeight }
  }
}
</script>
