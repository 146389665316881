import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import "element-plus/theme-chalk/index.css"
import i18n from "./i18n.js";
import './assets/main.css'
import { setGlobalContext } from 'javascript-common';

setGlobalContext({
    transformError: (error) => {
        alert(222);
        if (error && error.response && error.response.status) {
            switch(error.response.status) {
            case 400: return error.response.data;
            case 403: return { code: 403, message: 'Access denied' };
            default: return { code: error.response.status, message: 'Unhandled HTTP error' };
            }
        }
        else {
            return { code: 0, message: 'unknownError' };
        }
    },
    buildPath: (key) => '/REST/DSxClient/OrderingProxy/' + key,
    getServiceUrl: () => import.meta.env.VITE_API_URL,
    getPassportUrl: () => import.meta.env.VITE_PASSPORT_URL
});

const app = createApp(App)
app.use(ElementPlus)
app.use(i18n)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

app.mount('#app')
