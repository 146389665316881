//import { getData, putData, postData, patchData, deleteData } from 'javascript-common';
import { doGET } from './XHR.js';

export default {
    doGET(params) {
        params.path = 'REST/DSxClient/OrderingProxy/' + params.path;
        return doGET(params);
    },
    /*
    doPUT({ path, params = null, success = null }) {
        let promise = putData(path, { params }).promise;

        if(success) {
            promise = promise.then(success).catch(e => {
                ElMessage.error(e.message)
            });
        }

        return promise;
    },
    doPOST({ path, params = null, success = null }) {
        let promise = postData(path, { params }).promise;

        if(success) {
            promise = promise.then(success).catch(e => {
                ElMessage.error(e.message)
            });
        }

        return promise;
    },
    doPATCH({ path, params = null, success = null }) {
        let promise = patchData(path, { params }).promise;

        if(success) {
            promise = promise.then(success).catch(e => {
                ElMessage.error(e.message)
            });
        }

        return promise;
    },
    doDELETE({ path, params = null, success = null }) {
        let promise = (params ? deleteData(path, { params }) : deleteData(path)).promise;

        if(success) {
            promise = promise.then(success).catch(e => {
                ElMessage.error(e.message)
            });
        }

        return promise;
    }
    */
};
