let passportURL = import.meta.env.VITE_PASSPORT_URL;

function callXHR(url, options) {
    options = {
        method: 'GET',
        asynchronous: true,
        headers: {},
        parameters: null,
        onException: function(e) {
            throw e;
        },
        ...options
    };

    options.method = options.method.toUpperCase();

    if (options.parameters) {
        if ((typeof options.parameters) !== 'string') {
            var pairs = [];

            for (var i in options.parameters) {
                if (!Object.prototype.hasOwnProperty.call(options.parameters, i)) {
                    continue;
                }

                var key = encodeURIComponent(i);
                var value = options.parameters[i];

                if (Array.isArray(value)) {
                    for (var j = 0, c = value.length; j < c; j++) {
                        pairs.push(key + '=' + encodeURIComponent(value[j]));
                    }
                } else {
                    pairs.push(key + '=' + encodeURIComponent(value));
                }
            }

            options.parameters = pairs.join('&');
        }

        if (options.method === 'GET') {
            url += (url.match(/\?/) === null ? '?' : '&') + options.parameters;
        }
    }

    var transport = new XMLHttpRequest();

    if (options.withCredentials) {
        transport.withCredentials = true;
    }

    if (options.onCreate) {
        options.onCreate();
    }

    try {
        transport.open(options.method, url, options.asynchronous);

        transport.onreadystatechange = function() {
            if(transport.readyState !== 4) {
                return;
            }

            var status = transport.status || 0;
            var success = !status || (status >= 200 && status < 300) || status == 304;

            try {
                var callback = options['on' + status] || options['on' + (success ? 'Success' : 'Failure')] || function() {};
                callback(transport);
            } catch (e) {
                options.onException(e);
            }
        };

        if (options.method === 'POST' && !options.headers['Content-Type']) { // TODO check case insensitive
            options.headers['Content-type'] = options.contentType;

            if(options.encoding) {
                options.headers['Content-type'] += '; charset=' + options.encoding;
            }
        }

        for (var name in options.headers) {
            if (Object.prototype.hasOwnProperty.call(options.headers, name)) {
                transport.setRequestHeader(name, options.headers[name]);
            }
        }

        var body = options.method === 'POST' || options.method === 'PUT' || options.method === 'PATCH' ? options.postBody || options.parameters : null;
        transport.send(body);
    } catch (e) {
        options.onException(e);
    }
};

export function doGET(options) {
    let apiURL = import.meta.env.VITE_API_URL + options.path;
    let ticket = new URLSearchParams(document.location.search).get('ticket');

    if(ticket) {
        apiURL += ticket ? (apiURL.match(/\?/) ? '&' : '?') + 'ticket=' + ticket : '';
        let cleanedURL = new URL(location.href);
        cleanedURL.searchParams.delete('ticket');
        window.history.pushState({}, document.title, cleanedURL);
    }

    return new Promise((resolve, reject) => {
        callXHR(apiURL, {
            withCredentials: true,
            method: 'GET',
            on401: () => {
                if(ticket) {
                    throw new Error('Authentication with CAS ticket failed');
                }

                var previous = window.location.toString();
                window.location.assign(passportURL.replace(/\/+$/, '') + '/cas/login/?service=' + encodeURIComponent(previous));
            },
            onException: reject,
            on200: (transport) => {
                var data = JSON.parse(transport.responseText);
                resolve(data);
            },
            onSuccess: (transport) => {
                reject(new Error('Unexpected HTTP code ' + transport.status));
            },
            onFailure: () => {
                reject(new Error('Unexpected error'));
            }
        });
    });
};
