import { createI18n } from 'vue-i18n'

const getLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';

console.log(getLanguage());

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: getLanguage() == "fr" ? "fr" : "en",
    fallbackLocale: 'en', // set fallback locale
    messages: {
      en: {
        productName: 'Product Name',
        release:'Release',
        serialNumber:'Serial Number',
        licenseType:'License Type',
        supportEndDate:'Support End Date',
        expires:'Expires',
        productDetails:'Product Details',
        quantity:"Quantity",
        includedProducts:'Included Products',
        activeMachines:"Active Machines",
        includedProductPlaceholder:'No Included Product Available',
        siteSelectionPlaceholder:"Select Site",
        activated:"Activated",
        searchPlaceholder:"Type to search",
        adminRightErrorMessageLine1:"Product List is Restricted to Administrators",
        adminRightErrorMessageLine2:"Contact your support representative to request Security Admin rights in DSx.Client Care",
        noProduct:"No SOLIDWORKS Desktop products were found for your organization.",
        myProduct:"My Products",
        downloadCSV:"Download CSV",

      },
      fr: {
        productName: 'Product Name Fr',
        release:'Release',
        serialNumber:'Serial Number',
        licenseType:'License Type',
        supportEndDate:'Support End Date',
        expires:'Expires',
        productDetails:'Product Details',
        quantity:"Quantity",
        includedProducts:'Included Products',
        activeMachines:"Active Machines",
        includedProductPlaceholder:'No Included Product Available',
        siteSelectionPlaceholder:"Select Site",
        activated:"Activated",
        searchPlaceholder:"Type to search",
        adminRightErrorMessageLine1:"Product List is Restricted to Administrators",
        adminRightErrorMessageLine2:"Contact your support representative to request Security Admin rights in DSx.Client Care",
        noProduct:"No SOLIDWORKS Desktop products were found for your organization.",
        myProduct:"My Products",
        downloadCSV:"Download CSV",
      },
    }
})
export default i18n;