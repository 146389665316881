<template>
  <div id="productDetailView" :style="{ height: getViewportHeight }">
    <el-page-header @back="handleDialogModalContainerClose">
      <template #content>
        <span class="text-large font-600 mr-3"> {{ productDetail.productName }} </span>
      </template>
    </el-page-header>
    <el-row>
      <el-col :offset="2" :span="8">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>{{ $t('productDetails') }} </span>
            </div>
          </template>
          <el-row>
            <el-col class="adminPortalDetailViewLabel"> {{ $t('productName') }} </el-col>
            <el-col class="adminPortalDetailViewValue">{{ productDetail.productName }}</el-col>
          </el-row>
          <el-row>
            <el-col class="adminPortalDetailViewLabel">{{ $t('release') }}</el-col>
            <el-col class="adminPortalDetailViewValue">{{ productDetail.release }}</el-col>
          </el-row>
          <el-row>
            <el-col class="adminPortalDetailViewLabel">{{ $t('serialNumber') }}</el-col>
            <el-col class="adminPortalDetailViewValue">{{ productDetail.serialNumber }}</el-col>
          </el-row>
          <el-row>
            <el-col class="adminPortalDetailViewLabel">{{ $t('licenseType') }}</el-col>
            <el-col class="adminPortalDetailViewValue">{{ productDetail.licenseType }}</el-col>
          </el-row>
          <el-row>
            <el-col class="adminPortalDetailViewLabel">{{ $t('supportEndDate') }}</el-col>
            <el-col class="adminPortalDetailViewValue">{{ productDetail.supportEndDate }} </el-col>
          </el-row>
          <el-row>
            <el-col class="adminPortalDetailViewLabel">{{ $t('expires') }}</el-col>
            <!-- LN8-267 -->
            <el-col class="adminPortalDetailViewValue">{{
              productDetail.expires === '2100-01-01' ? 'Never' : productDetail.expires
            }}</el-col>
          </el-row>
          <el-row>
            <el-col class="adminPortalDetailViewLabel"> {{ $t('activeMachines') }} </el-col>
            <el-col>
              <el-scrollbar height="80px">
                <template v-for="machine in productDetail.machines" :key="machine">
                  <el-col class="adminPortalDetailViewValue">
                    {{ machine.name }}
                  </el-col>
                </template>
              </el-scrollbar>
            </el-col>
          </el-row>
        </el-card>
      </el-col>

      <el-col :offset="1" :span="10">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>{{ $t('includedProducts') }} </span>
            </div>
          </template>

          <el-col v-if="productDetail.children.length > 0">
            <el-row class="detailViewRow">
              <el-col :span="18" class="adminPortalDetailViewLabel">{{ $t('productName') }}</el-col>
              <el-col :span="4" class="adminPortalDetailViewLabel">{{ $t('quantity') }}</el-col>
            </el-row>
            <el-scrollbar height="400px">
              <el-row :span="8" :key="child" v-for="child in productDetail.children">
                <el-col :span="18" class="adminPortalDetailViewValue"> {{ child.name }} </el-col>
                <el-col :span="4" class="adminPortalDetailViewValue">{{ child.quantity }}</el-col>
              </el-row>
            </el-scrollbar>
          </el-col>
          <el-col v-else>
            <span class="adminPortalDetailViewLabel">{{ $t('includedProductPlaceholder') }}</span>
          </el-col>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
export default {
  name: 'ProductionGridView',
  props: ['openDetailPage', 'productDetail', 'selectedRow'],
  emits: ['closeDailog'],
  setup(props, context) {
    let getViewportHeight = ref([])
    getViewportHeight = computed(() => {
      return 700 + 'px'
    })
    function handleDialogModalContainerClose() {
      context.emit('closeDialog')
    }
    return { handleDialogModalContainerClose, getViewportHeight }
  }
}
</script>
>
